import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import API_BASE_URL from './Config';

const base_url = `${API_BASE_URL}`;

function LikeButton({ postId, slug, combinedIdentifier, postStatus, likes_count }) {
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(likes_count);
  const [userIdentifier, setUserIdentifier] = useState('');

  useEffect(() => {
    const getUserIdentifier = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
          throw new Error('Failed to fetch IP address');
        }
        const data = await response.json();
        const userIp = data.ip || 'unknown';
        setUserIdentifier(userIp);
      } catch (error) {
        console.error('Error fetching user identifier:', error);
      }
    };

    getUserIdentifier();
  }, []);

  useEffect(() => {
    if (likes_count !== undefined) {
      setLikeCount(likes_count);
      const likedPosts = Cookies.get('likedPosts');
      if (likedPosts) {
        const likedPostIds = likedPosts.split(',');
        if (likedPostIds.includes(postId.toString())) {
          setIsLiked(true);
        }
      }
    }
  }, [likes_count, postId, userIdentifier]);

  const handleLike = async () => {
    if (!isLiked) {
      setIsLiked(true);
      setLikeCount(prevCount => prevCount + 1);

      try {
        const response = await fetch(`${base_url}/wp-json/custom/v1/like`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            post_id: postId,
            user_ip: userIdentifier
          })
        });

        if (!response.ok) {
          throw new Error('Failed to like post');
        }

        // Update the liked posts in cookies
        let likedPosts = Cookies.get('likedPosts');
        let likedPostIds = likedPosts ? likedPosts.split(',') : [];
        likedPostIds.push(postId.toString());
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 365);
        Cookies.set('likedPosts', likedPostIds.join(','), { expires: expiryDate });
      } catch (error) {
        console.error('Error liking post:', error);
      }
    }
  };

  const handleUnlike = async () => {
    if (isLiked) {
      setIsLiked(false);
      setLikeCount(prevCount => prevCount - 1);

      try {
        const response = await fetch(`${base_url}/wp-json/custom/v1/unlike`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            post_id: postId,
            user_ip: userIdentifier
          })
        });

        if (!response.ok) {
          throw new Error('Failed to unlike post');
        }

        // Update the liked posts in cookies
        let likedPosts = Cookies.get('likedPosts');
        let likedPostIds = likedPosts ? likedPosts.split(',') : [];
        likedPostIds = likedPostIds.filter(id => id !== postId.toString());
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 365);
        Cookies.set('likedPosts', likedPostIds.join(','), { expires: expiryDate });
      } catch (error) {
        console.error('Error unliking post:', error);
      }
    }
  };

  const formatLikesCount = (count) => {
    return count >= 1000 ? (count / 1000).toFixed(1) + 'k' : count;
  };

  return (
    <div className="custom-like" slug={slug}>
      {isLiked ? (
        <a href="javascript:void(0)" onClick={handleUnlike} className="unlike-button" data-postid={postId} data-slug={slug}>
          <i className="fa fa-thumbs-up white-icon"></i>
        </a>
      ) : (
        <a href="javascript:void(0)" onClick={handleLike} className="like-button" data-postid={postId} data-slug={slug}>
          <i className="fa fa-thumbs-up"></i>
        </a>
      )}
      <span className="like-count" no_of_likes={likeCount} id={`like_count_${postId}`}>
        {formatLikesCount(likeCount)}
      </span>
    </div>
  );
}

export default LikeButton;
