import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import './Privacy.css';
import Footer from './footer';
import Category from './Category';
import arun from '../images/arun_profile.jpg';
import CategoryMob from './CategoryMob';

// import './BlogDetail.css';
function Privacy(){
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [isOpen, setIsOpen] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
  const [toggle, setToggle] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;
  const handleToggleCategory = () => {
    setToggle(!toggle);
  };
	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};
	
	const handleMouseOver = () => {
		setIsHovering(true);
	};

const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleisOpen = () => {
    setIsOpen(!isOpen);
  };
    return(
		<div className='privacy-page-content'>
		<div className='sidebar'>
		  <Category />
		</div>
		<div className="post-container main-container">
		<header className="header fixed">
			<nav className="nav navbar navbar-default">
			  <div className="navbar-header">
				<a href="/the-science-of-success/" className={`navbar-brand ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}> <span>THE SCIENCE OF <b>SUCCESS</b></span>
                <span className="txtcolor">Arun Nathani</span>
              </a>
			  </div>
			  {isMobile && (
             
              <button className="toggle-btn" onClick={toggleMenu} type="button" data-target="#navbarCollapse" data-toggle="collapse" class={` navbar-toggle ${isOpen ? 'active' : ''}`}>
              <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span>
          </button>
            )}
            {!isMobile && (
             <ul className="nav-list desktop-nav" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
             <li className={`nav-item ${currentPath === '/' ? 'active' : ''}`}>
               <Link to="/">Home</Link>
             </li>
             <li className={`nav-item ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}>
               <Link to="/the-science-of-success/">about</Link>
             </li>
           </ul>
            )}
              {isOpen && isMobile && (
              <ul className="nav-list mobile-nav ${isOpen ? 'open' : ''}`}">
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/the-science-of-success/">about</Link>
                </li>
<li className="nav-item category">
<Link onClick={handleToggleCategory}>Categories
                  { !isOpen === toggle ? <i class="fa fa-plus-circle" aria-hidden="true"></i> : <i class="fa fa-minus-circle" aria-hidden="true"></i> }
                  </Link>                </li>
                {toggle && <CategoryMob />}
              </ul>
            )}
			</nav>
			<div id="navbarCollapse" class="navbar-collapse pull-right collapse in" >
            <div class="menu-arun-blog-menu-container"><ul class="nav navbar-nav"><li id="menu-item-1495" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1495"><a href="https://www.arunnathaniblog.com/?home">HOME</a></li>
              <li id="menu-item-1496" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1496"><a href="https://www.arunnathaniblog.com/the-science-of-success/">ABOUT</a></li>
            </ul>
            </div>   
                      {/* <section class="col-sm-12 accordionMenu category-nav hidden-sm hidden-md hidden-lg">
              <a href="#">CATEGORIES <span class="fa fa-plus"></span></a>
            </section> */}
            </div>
			
			<div className="topbar ">
			  <div className="breadcrumb" id="breadcrumb">
				<ul id="breadcrumbs" className="breadcrumbs">
				  <li className="item-home"><a className="bread-link bread-home" href="/" title="Home">Home</a></li>
				  <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
				  <li className="item-current item-cat"><span className="bread-current bread-cat">Privacy Policy</span></li>
				  {/* <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li> */}
				</ul>
			  </div>
			</div>
		  </header>
      { isMobile && (
              <section className="mobile-Blogger hidden-xs">
                <div className="arun-mobile-imgblk">
              <a href="/the-science-of-success/">
                <img className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" /></a></div>
              <div className="ceo-text">
                <div className="mobile-BloggerName BloggerName">ARUN NATHANI</div>
                <div className="mobile-BloggerPost BloggerPost">
                  <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
                </div>
              </div>
            </section>
            )}
        <div className="inner-content privacy-page">
	<div className='post-detail'>
		<h2>Privacy Policy</h2>
		<div class="line clearfix">
			<div class="entry-info">
				<div class="col-xs-12 col-md-6"></div>

			</div>
		</div>
			<div id="wpa_content">					
			<p>At <a href="http://www.arunnathaniblog.com">www.arunnathaniblog.com</a> (herein referred to as “we” or “us” or “our”), we respect your need for online privacy and protect any personal information that you may share with us, in an appropriate manner. This privacy policy (“Privacy Policy”) applies to those who visit our website <a href="http://www.arunnathaniblog.com">www.arunnathaniblog.com</a> owned and operated by Arun Nathani. By using this site, you agree to the collection and use of information in accordance with this policy which may be updated from time to time.</p>
<p><strong>Information Recorded and Used</strong></p>
<p>Wherever required, any information you submit on this website may be used for purposes of providing you with access to certain features of site, verify your identity and to communicate with you. We will only use the information collected in a manner that is consistent with this Privacy Policy.</p>
<p>We are committed to comply with relevant laws in respect of personal data, and the protection of the “rights and freedom” of individuals whose information we collect and process. We endeavor to keep your information protected in the best possible manner.</p>
<p>We ensure the following to safeguard the individual’s interest with regard to the personal information shared with us:</p>
<ul>
<li>Opt – in: &nbsp;Where we control the information, we will ensure that your consent is taken before the information is collected.</li>
<li>Updating our legal terms of use</li>
</ul>
<p>We may, if you so choose, send direct mailers to you at the address given by you. We provide you the option to ‘opt-out’ of this direct mailer by way of links provided at the bottom of each mailer. We respect your privacy and, in the event, that you choose to not receive such mailers, we will take all necessary and reasonable steps to remove you from the list. If you are uncertain whether this Privacy Policy conflicts with the applicable local privacy laws where you are located, please do not submit your personal information on this website.</p>
<p><strong>Accessing, Updating and Removing Personal Information</strong></p>
<p>Upon request, we will provide you with information about whether we hold any of your Personal Information. Users who wish to correct, update or remove any personal information including those from a public forum, directory or testimonial on our site may do so by accessing their user account. In addition, you should be aware that it is not always possible to completely remove or delete all your information from our databases without some residual data because of backups or other technology related reasons.</p>
<p><strong>Investigation of Illegal Activity</strong></p>
<p>We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law.</p>
<p>We reserve the right to disclose and provide your personal information where required by law or to comply with valid legal processes (such as search warrant, subpoena or court order) as well as to protect its rights or property, or in a case of potential violation of the terms and conditions of services. However, we will ensure that such access is in compliance with this Privacy Policy Statement and is subjected to appropriate confidentiality and security measures.</p>
<p><strong>Usage Details</strong></p>
<p>Your usage details such as time, frequency, duration and pattern of use, features used and the amount of storage used will be recorded by us in order to enhance your experience of the website and to help us provide you the best possible service.</p>
<p><strong>Visitor Details</strong></p>
<p>We use the Internet Protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in our log files to analyze the trends, administer the website, track visitor’s movements and to improve our website. We link this automatically collected data to other information we collect about you.</p>
<p><strong>Analytics</strong></p>
<p>We use third party software in a controlled environment for analytics. All metrics information collected from usage of the website is used to evaluate how users use the website, and to compile statistical reports on activity for us</p>
<p>We further use the same statistical analytics tool to maintain, enhance, or add to the functionality of the website and to create a personalized experience for you. Anonymized and aggregated information and analysis may be made public where desired by the website.</p>
<p><strong>Children’s Personal Information</strong></p>
<p>The website does not knowingly collect any personal information from children under the age of 13 or of any age defined by governing laws for children (“prescribed age”). If you are under such prescribed age, please do not submit any personal information through our website. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this policy by instructing their children never to provide personal information through our website without their permission. If you have reason to believe that a child below the prescribed age has provided personal information to the website, please contact us.</p>
<p><strong>Cookies Policy</strong></p>
<p>We use cookies on our websites for a variety of reasons which you can learn about below. The cookies we use do not store personally identifiable information nor can they harm your computer. We want our website to be informative, personal, and as user friendly as possible and cookies help us to achieve that goal.</p>
<p>By using our website, you agree to the use of cookies and other technologies as set out in this policy. We appreciate some users may like more individual control over their visit to our website and can adjust their settings accordingly. You can read all about this in the section below “Disabling/enabling cookies via your browser”. If you do not agree to such use, please refrain from using the website.</p>
<p><strong>We also use cookies for additional purposes, including:</strong></p>
<ul>
<li>Make our website work as you would expect.</li>
<li>Ensure your security and privacy when you visit our website or mobile application.</li>
<li>Continuously improve our website or mobile application for you.</li>
<li>Preventing fraudulent activity.</li>
<li>Improving security.</li>
</ul>
<p><strong>We do not use cookies for:</strong></p>
<ul>
<li>Collection of any personal&nbsp;information (without your consent/permission)</li>
<li>Collection of any sensitive information (without your consent/permission)</li>
<li>Share any data to third parties</li>
</ul>
<p><strong>Links from our website</strong></p>
<p>Some pages of our website contain external links. We do not warrant that the content of such websites, their cookie policies, privacy policies or information governance policies are in accordance with the applicable data protection and privacy laws. You are advised to verify the privacy practices of such other websites. We are not responsible for the manner of use or misuse of information made available by you at such other websites. We encourage you not to provide personal information, without assuring yourselves of the Privacy Policy Statement of other websites. Any access by you to the external links does not constitute an assumption of liability on us in relation to content or usage of your personal information on or by those external links.</p>
<p><strong>With whom we share Information</strong></p>
<p>We may need to share your personal information and your data to our affiliates, service providers and business partners solely for the purpose of providing our services to you. The purposes for which we may disclose your personal information or data to our service providers may include, but are not limited to, data storage, database management, web analytics and payment processing. These service providers are authorized to use your personal information or data only as necessary to provide these services to us. In such cases we do not ensure that such affiliates, service providers and business partners comply with this Privacy Policy Statement and adopt appropriate confidentiality and security measures. We will obtain your prior specific consent before we share your personal information or data to any outside person for any purpose that is not directly connected with providing our services to you. We do not sell your personal information to third parties. We may share generic aggregated demographic information not linked to any personal information regarding visitors and users with our business partners.</p>
<p><strong>Legal Disclosure</strong></p>
<p>Please be aware that laws in various jurisdictions in which we operate may obligate us to disclose your personal information to the local law enforcement authorities under a legal process or an enforceable government request. In addition, we may also disclose personal information to law enforcement authorities if such disclosure is determined to be necessary by us in our sole and absolute discretion for protecting the safety of our users, employees, or the general public.</p>
<p><strong>How secure is your Information</strong></p>
<p>We have adopted industry appropriate data collection, storage and processing practices and security measures, as well as physical security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information.</p>
<p><strong>Your Choice in Information Use</strong></p>
<p>In the event we decide to use your personal information for any purpose other than as stated in this Privacy Policy Statement, we will ask for your consent or offer you an effective way to opt out of the use of your personal information for those other purposes.</p>
<p><strong>Amendments</strong></p>
<p>Amendments to this Policy will be posted to this URL and will be effective when posted. You should frequently visit this Policy to check for amendments that are made. Your continued use of this Website following the posting of any amendment, modification, or change to this Policy shall constitute your acceptance of the amendments to this Policy. You can choose to discontinue use of the Website, if you do not accept the terms of this Policy, or any modified version of this Policy.</p>
<p><strong>External widgets</strong></p>
<p>We provide the users third party widgets such as Facebook, Twitter, and LinkedIn buttons on the website that allow users to share blog articles different platforms. These widgets may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the widgets to function properly. These widgets do not collect or store any personal information from users on the website and simply act as a bridge for your convenience in sharing information. Your interactions with these widgets are governed by the privacy policy of the company providing it.</p>
<p><strong>Enforcement of Privacy Policy</strong></p>
<p>We make every effort to ensure that personal information provided by you is used in conformity with this Privacy Policy Statement. If you have any questions regarding this Privacy Policy or your personal information so collected, kindly contact us at <a href="mailto:arunnathaniblog@cybage.com">arunnathaniblog@cybage.com</a>.</p>
<p><strong>Contents of your User Account</strong></p>
<p>We store and maintain data stored in your user account. In order to prevent loss of data due to errors or system failures, we also keep backup copies of data including the contents of your user account. Hence your files and data may remain on our servers even after deletion or termination of your user account. We may retain and use your personal information and data as necessary to comply with our legal obligations, resolve disputes, and enforce our rights. We assure you that the contents of your user account will not be disclosed to anyone and will not be accessible even to our employees except in circumstances specifically mentioned in this Privacy Policy Statement and Terms of Services. We also do not scan the contents of your user account for serving targeted advertisements, username, password, transaction information and data stored in your user account. Access to your name and email address is restricted to our employees who need to know such information in connection with providing Services to you and are bound by confidentiality obligations.</p>
<p>From time to time, we may send emails to you regarding new services, releases and upcoming events. You may opt out of receiving newsletters and other secondary messages from us by selecting the ‘unsubscribe’ function present in every email we send. However, you will continue to receive essential transactional emails.</p>
<p><strong>Behavioral Targeting/ Re-Targeting</strong></p>
<p>We partner with third parties to manage our advertisements on other sites. Our third-party partners may use technologies such as cookies to gather information about your activities on this site and other sites in order to provide you advertising based upon your browsing activities and interests.</p>
	
			</div>				
		</div>

</div>
<Footer />
</div>
</div>
    );
}

export default Privacy;