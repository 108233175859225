import React, { useState, useEffect } from 'react';
import { useNavigate,useParams, Link, useLocation } from 'react-router-dom';
import './Blog.css';
import Footer from './footer';
import Category from './Category';
import LikeButton from './LikeButton'; // Import the LikeButton component
import arun from '../images/arun_profile.jpg';
import CategoryMob from './CategoryMob';
import ClipLoader from "react-spinners/ClipLoader";
import API_BASE_URL from './Config';

const base_url = `${API_BASE_URL}`;

 
function CategoryBlog({ scrollToSection,postId, slug, combinedIdentifier }) {
  const { category, subcategory } = useParams();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]); // Define categories state variable
  const [featuredImages, setFeaturedImages] = useState({});
  const [postStatus, setPostCounts] = useState({});
  const [categoryData, setcategoryData] = useState([]); // Define categories state variable

  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [sort, setSort] = useState('desc');
  const [style, setStyle] = useState('356'); // Default to 'All'
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(!isOpen === toggle){
      setToggle(!toggle)
    }
  };
 
  const handleMouseOver = () => {
    setIsHovering(true);
  };
 
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleCommentClick = (slug) => {
    navigate(`/${slug}`);
    };
    const handleToggleCategory = () => {
      setToggle(!toggle);
    };
 
     // Handle form submission
 const handleSortChange = (e) => {
  // setSort(e.target.value);
  const newSort = e.target.value;
  setSort(newSort);

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('sort', newSort);
  searchParams.set('style', style);
  navigate(`${currentPath}?${searchParams.toString()}`);
};
 
const handleStyleChange = (e) => {
  // setStyle(e.target.value);
  const newStyle = e.target.value;
  setStyle(newStyle);

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('style', newStyle);
  searchParams.set('sort', sort);
  navigate(`${currentPath}?${searchParams.toString()}`);
};
 
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${base_url}/wp-json/custom/v1/categories`);
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
 
    fetchCategories();
  }, []);
 
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sort = params.get('sort');
    const style = params.get('style');
    const currentPage = params.get('page') || 1; // Default to page 1 if not set
    setCurrentPage(Number(currentPage));

  
    if (sort) {
      setSort(sort);
    }
    if (style) {
      setStyle(style);
    }

    setIsLoading(true);
    const fetchPosts = async () => {
      try {
        let url = `${base_url}/wp-json/wp/v2/posts`;
        const categoryFilters = [];
        if (subcategory) {
          const subcategoryResponse = await fetch(`${base_url}/wp-json/wp/v2/categories?slug=${subcategory}`);
          const subcategoryData = await subcategoryResponse.json();
          const subcategoryId = subcategoryData.length > 0 ? subcategoryData[0].id : null;
          setcategoryData(subcategoryData);
          // if (subcategoryId) {
          //   url += `?categories=${subcategoryId}`;
          // } else {
          //   console.error('Subcategory not found');
          //   return;
          // }
           // Apply category filters
     
        if (subcategoryId) {
          categoryFilters.push(subcategoryId);
        }
 
     
       
        } else if (category) {
            const categoryResponse = await fetch(`${base_url}/wp-json/wp/v2/categories?slug=${category}`);
            const categoryData = await categoryResponse.json();
            const categoryId = categoryData.length > 0 ? categoryData[0].id : null;
           
          // if (categoryId) {
          //   url += `?categories=${categoryId}`;
          // } else {
          //   console.error('Category not found');
          //   return;
          // }
             // Apply category filters
       
        if (categoryId) {
          categoryFilters.push(categoryId);
        }
 
     
 
        }
 
 
 
        if (style !== '356') { // '356' means 'All'
          categoryFilters.push(style);
        }
       
        if (categoryFilters.length > 0) {
          url += `?categories=${categoryFilters.join(',')}`;
        }
 
        url += `&page=${currentPage}&per_page=15`;
        url += `&sort=${sort}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setPosts(data);
        setDataLoaded(true);
        const totalPages = response.headers.get('X-WP-TotalPages');
        if (totalPages) {
          setTotalPages(parseInt(totalPages));
        }
 
        // Fetch featured images
        // const featuredImages = {};
        // const postStatus = {};
        // for (const post of data) {
        //   if (post.featured_media) {
        //     try {
        //       const imageResponse = await fetch(`https://www.arunnathaniblog.com/wp-json/custom/v1/featured-image/${post.id}`);
        //       if (imageResponse.ok) {
        //         const imageData = await imageResponse.json();
        //         featuredImages[post.id] = imageData.featured_image;
        //       }
        //     } catch (error) {
        //       console.error('Error fetching featured image: ', error);
        //     }
        //   }
 
        //   try {
        //     const statusResponse = await fetch(`https://www.arunnathaniblog.com/wp-json/custom/v1/post_status/${post.id}`);
        //     if (statusResponse.ok) {
        //       const countData = await statusResponse.json();
        //       postStatus[post.id] = {
        //         no_of_likes: countData.no_of_likes,
        //         likes_count: countData.likes_count,
        //         comments_count:countData.comments_count,
        //         views_count: countData.views_count,            
        //         length_count: countData.length_count
 
        //       };
             
        //     } else {
        //       console.error('Failed to fetch post status for post ID:', post.id);
        //     }
        //   } catch (error) {
        //     console.error('Error fetching post status:', error);
        //   }
 
        // }
        // setPostCounts(postStatus);
        // setFeaturedImages(featuredImages);
 
      } catch (error) {
        console.error('Error fetching posts: ', error);
      }finally{
        setIsLoading(false);
      }
    };
 
    fetchPosts();
  }, [category, subcategory, currentPage, categories, sort, style ]);
 
  const goToPage = (page) => {
    setCurrentPage(page);
    navigate(`${location.pathname}?page=${page}&sort=${sort}&style=${style}`);
    window.scrollTo(0, 0);
  };
 
  const goToPreviousPage = () => {
    // setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    navigate(`${location.pathname}?page=${prevPage}&sort=${sort}&style=${style}`);
  };
 
  const goToNextPage = () => {
    // setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    navigate(`${location.pathname}?page=${nextPage}&sort=${sort}&style=${style}`);
  };
 
  const pageRange = () => {
    const range = [];
    let start = Math.max(1, currentPage - 1);
    let end = Math.min(totalPages, currentPage + 1);
    if (totalPages <= 4) {
      start = 1;
      end = totalPages;
    } else {
      if (currentPage <= 2) {
        end = 3;
      } else if (currentPage >= totalPages - 1) {
        start = totalPages - 2;
      }
    }
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };
  const handleisOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleReadMore = (slug) => {
    navigate(`/${slug}`);
    window.scrollTo(0, 0); // Scroll to top when page number is clicked
  };
  return (
    <div className='blog-category'>
      <div className='sidebar'>
        <Category />
      </div>
      <div className="post-container main-container">
      <header className="header fixed">
         <nav className="nav navbar navbar-default">
            <div className="navbar-header">
            <a href="/the-science-of-success/" className={`navbar-brand ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}> <span>THE SCIENCE OF <b>SUCCESS</b></span>
                <span className="txtcolor">Arun Nathani</span>
              </a>
            </div>
            {isMobile && (
             
              <button className="toggle-btn" onClick={toggleMenu} type="button" data-target="#navbarCollapse" data-toggle="collapse" class={` navbar-toggle ${isOpen ? 'active' : ''}`}>
              <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span>
          </button>
            )}
            {!isMobile && (
              <ul className="nav-list desktop-nav" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <li className={`nav-item ${currentPath === '/' ? 'active' : ''}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`nav-item ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}>
                <Link to="/the-science-of-success/">about</Link>
              </li>
            </ul>
            )}
            {isOpen && isMobile && (
              <ul className="nav-list mobile-nav ${isOpen ? 'open' : ''}`}">
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link  onClick={handleisOpen} to="/the-science-of-success/">about</Link>
                </li>
                <li className="nav-item category">
                <Link onClick={handleToggleCategory}>Categories
                  { !isOpen === toggle ? <i class="fa fa-plus-circle" aria-hidden="true"></i> : <i class="fa fa-minus-circle" aria-hidden="true"></i> }
                  </Link>                </li>
                {toggle && <CategoryMob />}
              </ul>
            )}
          </nav>
          <div id="navbarCollapse" class="navbar-collapse pull-right collapse in" >
            <div class="menu-arun-blog-menu-container"><ul class="nav navbar-nav"><li id="menu-item-1495" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1495"><a href="https://www.arunnathaniblog.com/?home">HOME</a></li>
              <li id="menu-item-1496" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1496"><a href="https://www.arunnathaniblog.com/the-science-of-success/">ABOUT</a></li>
            </ul>
            </div>   
            </div>
           
          <div className="topbar ">
            <div className="breadcrumb" id="breadcrumb">
              <ul id="breadcrumbs" className="breadcrumbs">
                <li className="item-home"><a className="bread-link bread-home" href="/" title="Home">Home</a></li>
                <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
                <Link to={`/category/${category}`}><li className="item-current item-cat"><span className="bread-current bread-cat">{category}</span></li></Link>
                <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
                <li className="item-current item-cat"><span className="bread-current bread-cat">{categoryData[0] && categoryData[0].name ? categoryData[0].name : ''}
                </span></li>
              </ul>
            </div>
          </div>
        </header>
        
        { isMobile && (
              <section className="mobile-Blogger hidden-xs">
                <div className="arun-mobile-imgblk">
                <a href="/the-science-of-success/">
                <img loading="lazy" className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" /></a></div>
              <div className="ceo-text">
                <div className="mobile-BloggerName BloggerName">ARUN NATHANI</div>
                <div className="mobile-BloggerPost BloggerPost">
                  <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
                </div>
              </div>
            </section>
            )}

       

          <div className="pager">
            {totalPages > 1 && (
              <>
                {currentPage > 1 && (
                  <button 
                    className="prev page-numbers" 
                    onClick={goToPreviousPage}
                  >
                    ← Previous
                  </button>
                )}
                <button
                  key={1}
                  className={`page-numbers ${currentPage === 1 ? 'current' : ''}`}
                  onClick={() => goToPage(1)}
                >
                  1
                </button>
                {currentPage > 3 && <span className="dots">...</span>}
                {pageRange().map((page) => (
                  page !== 1 && page !== totalPages && (
                    <button
                      key={page}
                      className={`page-numbers ${currentPage === page ? 'current' : ''}`}
                      onClick={() => goToPage(page)}
                    >
                      {page}
                    </button>
                  )
                ))}
                {currentPage < totalPages - 2 && <span className="dots">...</span>}
                <button
                  key={totalPages}
                  className={`page-numbers ${currentPage === totalPages ? 'current' : ''}`}
                  onClick={() => goToPage(totalPages)}
                >
                  {totalPages}
                </button>
                {currentPage < totalPages && (
                  <button 
                    className="next page-numbers" 
                    onClick={goToNextPage}
                  >
                    Next →
                  </button>
                )}
              </>
            )}
          </div>
           <form method="post" action="" name="frm_filter" id="frm_filter">
                <div class="filetr-ondetail">										
                  <div class="selectgrp ">
                  <label class="select-list"><span>Sort By: </span>
                          <select id="group-list" name="group-list" class="group-list" onChange={handleSortChange} value={sort}>
                            <option value="desc">Latest</option>
                            <option value="popular">Popular</option>
                            <option value="short">Short</option>
                            <option value="long">Long</option>
                          </select>
                    </label>						
                  </div>
                  
                  <div class="selectgrp ">
                    <label class="select-list"><span>Style: </span>
                          <select name="sel_style_cat" id="group-list" class="group-list" onChange={handleStyleChange} value={style}>
                            <option value="356">All</option>				   
                            <option value="359">Adapted</option>				   
                            <option value="358">Original</option>				   
                            <option value="360">Video</option>
                          </select>				
                    </label>						
                  </div>
                </div>
              </form>
       
        <div id="masonry" className="isotope">
        <div className="row">
        {dataLoaded && posts.length === 0 ? (
            <div class="empty-records">This one does not work. Take a look at the other blogs.</div>
          ) : (
            posts.map((post) => {            
              //Variable to hold category names
              let categoryNames = post.categories.map(categoryId => {
                  const category = categories.find(cat => cat.id === categoryId);
                  return category ? category.name : null;
                }).filter(categoryName => !["All", "all", "style", "Style"].includes(categoryName))
                .reduce((accumulator, categoryName, index, array) => {if (categoryName) {if (accumulator !== '') {accumulator += ', ';}
                    accumulator += categoryName;}return accumulator;}, '');
                   
              //Variable to hold subcategory names
              let subCategoryNames = categories.map(category =>
                category.subcategories
                  .filter(subcategory => !['Original', 'Video', 'Adapted', 'All'].includes(subcategory.name))
                  .map(subcategory => (
                    post.categories.includes(subcategory.id) ? subcategory.name : null
                  ))
                  .filter(name => name !== null)
              );
 
 
              return (
              <div key={post.id} className="post col-md-3 isotope-item">
                <div className={`post_content ${post.featured_image ? 'post-with-Img' : 'post-no-Img'}`}>
                            {post.featured_image && (
                            <div className="row post-image-container">    
                                <a href={`/${post.slug}`} rel="bookmark">
                                  <img
                                    title={post.title.rendered}
                                    alt={post.title.rendered}
                                    className="post-image responsive-img"
                                    src={post.featured_image}
                                  />
                                </a>
                              </div>
                            )}
                  <article className="article">
                    <h3 className="title">
                    <a href={`/${post.slug}`} rel="bookmark" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                    </h3>
                    <div className="line">
                      <div className="entry-info col-xs-12">
                        <div className="entry-date pull-left">
                          <label>Date: </label>
                          <span>{new Date(post.date).toShortFormat()}</span>
                        </div>
                        <div className="entry-date pull-left">
                          <label>Length:</label>
                          <span>{post.length_count ? `${post.length_count} min${post.length_count > 1 ? 's.' : '.'}` : '0 min.'}</span>
                        </div>
                        <div className="entry-standard entry-style col-xs-6">
                          <label>Category:</label>
                          <a href={`/category/${String(categoryNames).toLowerCase().replace(/\s+/g, '-')}`}><span>{categoryNames}</span></a>
                          </div>
                          <div className="main-category-sub col-xs-6">
                            <label>Subcategory:</label>
                            <a href={`/category/${String(categoryNames).toLowerCase().replace(/\s+/g, '-')}/${String(subCategoryNames).toLowerCase().replace(/\s+/g, '-').replace(/,/g, '')}`}><span>{subCategoryNames}</span></a>
                        </div>
                      </div>
                      {
                  post.story_parts != null ? (
                    [4220, 4216, 4218, 4223].includes(post.id) ? (
                      <div className='version'>
                        {Array.isArray(post.story_parts) ? (
                          post.story_parts.map((part, index) => (
                            <div key={index}>
                              <a href={part.story_link}> {`Version-${part.parts_number}`}</a>
                            </div>
                          ))
                        ) : (
                          typeof post.story_parts === 'object' ? (
                            <div>
                              {Object.keys(post.story_parts).map((key, index) => (
                                <div key={index}>
                                  <a href={post.story_parts[key].story_link}>{`Version-${post.story_parts[key].parts_number}`}</a>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Invalid story parts format.</div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className='parts'>
                        <label>Parts:</label>
                        {Array.isArray(post.story_parts) ? (
                          post.story_parts.map((part, index) => (
                            <div key={index}>
                              <a href={part.story_link}>{part.parts_number}</a>
                            </div>
                          ))
                        ) : (
                          typeof post.story_parts === 'object' ? (
                            <div>
                              {Object.keys(post.story_parts).map((key, index) => (
                                <div key={index}>
                                  <a href={post.story_parts[key].story_link}>{post.story_parts[key].parts_number}</a>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Invalid story parts format.</div>
                          )
                        )}
                      </div>
                    )
                  ) : null
                }
                    </div>
                    <p className="post-description" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                   
                  </article>
                 
                 
 
                </div>
                <div className="menu-social-links-menu-container social-icons">
                  <div className="action-links">
                      <p className="read-more">
                      <button onClick={() => handleReadMore(post.slug)}>Continue Reading</button>
                      </p>
                    </div>
                    <ul className="nav-section-new">
                      <li className="nav-item menu-li-soc">
                        <a href="https://www.facebook.com/cybage/" target="_blank">
                          <span className="fa fa-facebook"></span>
                        </a>
                      </li>
                      <li className="nav-item menu-li-soc">
                        <a href="http://twitter.com/cybagesoftware" target="_blank">
                          <span className="fa fa-brands fa-x-twitter"></span>
                        </a>
                      </li>
                      <li className="nav-item menu-li-soc">
                        <a href="https://www.linkedin.com/company/cybage/" target="_blank">
                          <span className="fa fa-linkedin"></span>
                        </a>
                      </li>
                      <LikeButton postId={post.id} slug={post.slug} combinedIdentifier={combinedIdentifier} postStatus={postStatus} likes_count= {post.likes_count} />
 
                      {/* <LikeButton postId={post.id} slug={post.slug} combinedIdentifier={combinedIdentifier} postStatus= {postStatus} /> */}
 
                      {/* <div onClick={scrollToSection} className="custom-like" slug={post.slug}>
                        <a href="javascript: void(0)" className="like-button" data-postid={post.id} data-slug={post.slug}>
                          <i className="fa fa-thumbs-up"></i>
                        </a>
                       
                        {postStatus[post.id] && (
                          <span className="like-count" no_of_likes={postStatus[post.id].no_of_likes} id={`like_count_${post.id}`}>
                            {postStatus[post.id].likes_count}
                          </span>                        )}
                      </div> */}
                      <span className="pull-right comment-cnt">
                        <a href={`/${post.slug}#comments`} >
                          <span className="fa fa-comment" ></span> 
                          {post.comments_count ? post.comments_count : '0'}
                        </a>
                      </span>
                    </ul>
                  </div>
              </div>
            );
            })
          )}
 
          </div>
        </div>
        <div className="pager bottom-pager">
            {totalPages > 1 && (
              <>
                {currentPage > 1 && (
                  <button 
                    className="prev page-numbers" 
                    onClick={goToPreviousPage}
                  >
                    ← Previous
                  </button>
                )}
                <button
                  key={1}
                  className={`page-numbers ${currentPage === 1 ? 'current' : ''}`}
                  onClick={() => goToPage(1)}
                >
                  1
                </button>
                {currentPage > 3 && <span className="dots">...</span>}
                {pageRange().map((page) => (
                  page !== 1 && page !== totalPages && (
                    <button
                      key={page}
                      className={`page-numbers ${currentPage === page ? 'current' : ''}`}
                      onClick={() => goToPage(page)}
                    >
                      {page}
                    </button>
                  )
                ))}
                {currentPage < totalPages - 2 && <span className="dots">...</span>}
                <button
                  key={totalPages}
                  className={`page-numbers ${currentPage === totalPages ? 'current' : ''}`}
                  onClick={() => goToPage(totalPages)}
                >
                  {totalPages}
                </button>
                {currentPage < totalPages && (
                  <button 
                    className="next page-numbers" 
                    onClick={goToNextPage}
                  >
                    Next →
                  </button>
                )}
              </>
            )}
          </div>
        <Footer />
      </div>
   </div>
  );
}
 
export default CategoryBlog;