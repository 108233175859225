const getAPIBaseUrl = () => {
    const currentDomain = window.location.origin;
  
    if (currentDomain.includes("localhost")) {
      return "https://dev-arun-nathani-blog.pantheonsite.io";
    } else if (currentDomain.includes("dev")) {
        return "https://dev-arun-nathani-blog.pantheonsite.io";
    } else if (currentDomain.includes("test")) {
      return "https://test-arun-nathani-blog.pantheonsite.io";
    } else {
      return "https://www.arunnathaniblog.com";
    }
  };
  
  const API_BASE_URL = getAPIBaseUrl();
  
  export default API_BASE_URL;  // ✅ Default export
  