import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './Blog.css';
import Footer from './footer';
import facebook from '../images/facebook_32.png';
import linkedin from '../images/linkedin_32.png';
import twitter from '../images/twitter_32.png';
import arun from '../images/arun_profile.jpg';
import Category from './Category';
import LikeButton from './LikeButton'; // Import the LikeButton component
import CategoryMob from './CategoryMob';
// import ClipLoader from "react-spinners/ClipLoader";
import API_BASE_URL from './Config';

const base_url = `${API_BASE_URL}`;



function Blog({ scrollToSection,postId, slug, combinedIdentifier }) {
  
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isHovering, setIsHovering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [searchfilterdBlogs, setSearchFilteredBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [featuredImages, setFeaturedImages] = useState({});
  const [postStatus, setPostCounts] = useState({});
  const [sortOrder, setSortOrder] = useState('DESC'); // State for sorting
const [toggle, setToggle] = useState(true);
const [isLoading, setIsLoading] = useState(false);
const [searchtoggle, setSearchToggle] = useState(false);
const [searchname, setSearchName] = useState('');
const [showSearchTopbar, setShowSearchTopbar] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  const query = new URLSearchParams(location.search);
  const filter = query.get('filter') || 'popular';
  const order = query.get('order');
  const getToggleOrder = (currentOrder) => (currentOrder === 'asc' ? 'desc' : 'asc');
  const newOrder = getToggleOrder(order);
  const arrowClass = order === 'asc' ? 'rotate180' : 'rotate0';
  const currentPath = location.pathname;
  const handleSearchMobile = () => {
   setSearchToggle(!searchtoggle);
  }

  const handleToggleCategory = () => {
    setToggle(!toggle);
  };
  const handleisOpen = () => {
    setIsOpen(!isOpen);
  };
  const decodeHTML = (html) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
    if (window.innerWidth >= 768) {
      setIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(!isOpen === toggle){
      setToggle(!toggle)
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const query = new URLSearchParams(location.search);
        const currentPage = query.get('page') || 1; // Default to page 1 if not set
        const filter = query.get('filter') || 'popular';
        const order = query.get('order') || '';
        const search = query.get('s') || '';
        setSearchName(search);
        setShowSearchTopbar(search !== '');
        setCurrentPage(Number(currentPage));

        const url = `${base_url}/wp-json/wp/v2/posts?page=${currentPage}&per_page=15${filter ? `&filter=${filter}` : ''}${order ? `&order=${order}` : ''}${search ? `&search=${search}` : ''}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setPosts(data);
        setSearchFilteredBlogs(data);
        const totalPages = response.headers.get('X-WP-TotalPages');
        if (totalPages) {
          setTotalPages(parseInt(totalPages));
        }
        
      } catch (error) {
        console.error('Error fetching posts: ', error);
      }  finally{
        setIsLoading(false);
        setDataLoaded(true);
      }
    };

    fetchData();
  }, [currentPage, location.search]);

  useEffect(() => {
    fetch(`${base_url}/wp-json/custom/v1/categories`)
      .then(response => response.json())
      .then(data => {
        setCategories(data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const toggleCategory = categoryId => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(prevState =>
        prevState.filter(id => id !== categoryId)
      );
      setSearchFilteredBlogs(posts);
    } else {
      setExpandedCategories(prevState => [...prevState, categoryId]);
      const filtered = posts.filter(blog =>
        expandedCategories.includes(categoryId) ||
        blog.categories.includes(categoryId) ||
        categories.some(category =>
          category.subcategories.includes(categoryId)
        )
      );
      setSearchFilteredBlogs(filtered);
    }
  };

  const handleSearch = searchTerm => {
    const filtered = posts.filter(blog =>
      (blog.title.rendered.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.content.rendered.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (expandedCategories.length === 0 || blog.categories.some(categoryId =>
        expandedCategories.includes(categoryId) || categories.some(category =>
          category.subcategories.includes(categoryId)
        )
      ))
    );

    setSearchFilteredBlogs(filtered);
  };

  const handleSubcategorySearch = subcategoryId => {
    const filtered = posts.filter(blog =>
      blog.categories.includes(subcategoryId)
    );
    setSearchFilteredBlogs(filtered);
  };

  const goToPage = (page) => {
    const query = new URLSearchParams(location.search);
    query.set('page', page);
    navigate(`/?${query.toString()}`);
    setCurrentPage(page);
    window.scrollTo(0, 0); // Scroll to top when page number is clicked
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  };

  const pageRange = () => {
    const range = [];
    let start = Math.max(1, currentPage - 1);
    let end = Math.min(totalPages, currentPage + 1);
    if (totalPages <= 4) {
      start = 1;
      end = totalPages;
    } else {
      if (currentPage <= 2) {
        end = 3;
      } else if (currentPage >= totalPages - 1) {
        start = totalPages - 2;
      }
    }
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const handleReadMore = (slug) => {
    navigate(`/${slug}`);
    window.scrollTo(0, 0); // Scroll to top when page number is clicked
  };

  const matchCategories = (posts, categories) => {
    posts.forEach(post => {
      const matchedCategories = [];
      post.categories.forEach(postCategoryId => {
        const matchedCategory = categories.find(category => category.id === postCategoryId);
        if (matchedCategory) {
          matchedCategories.push(matchedCategory);
        }
      });
      // console.log('Matched categories for post:', matchedCategories);
    });
  };

  const matchSubcategories = (posts, categories) => {
    posts.forEach(post => {
      const matchedSubcategories = [];
      post.categories.forEach(postCategoryId => {
        const matchedCategory = categories.find(category => category.id === postCategoryId);
        if (matchedCategory) {
          matchedCategory.subcategories.forEach(subcategoryId => {
            const matchedSubcategory = categories.find(category => category.id === subcategoryId);
            if (matchedSubcategory) {
              matchedSubcategories.push(matchedSubcategory);
            }
          });
        }
      });
      // console.log('Matched subcategories for post:', matchedSubcategories);
    });
  };

  matchCategories(posts, categories);
  matchSubcategories(posts, categories);
  const handleCommentClick = (slug) => {
    navigate(`/${slug}`);
    };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
    const query = new URLSearchParams(location.search);
    query.set('filter', e.target.value);
    navigate(`/?${query.toString()}`);
  };

  const sortPosts = (posts) => {
    switch (sortOrder) {
      case 'DESC':
        return [...posts].sort((a, b) => new Date(b.date) - new Date(a.date));
      case 'POPULAR':
        return [...posts].sort((a, b) => (postStatus[b.id]?.views_count || 0) - (postStatus[a.id]?.views_count || 0));
      case 'short':
        return [...posts].sort((a, b) => postStatus[a.id]?.length_count - postStatus[b.id]?.length_count); //length_count
      case 'long':
        return [...posts].sort((a, b) => postStatus[b.id]?.length_count - postStatus[a.id]?.length_count);
      default:
        return posts;
    }
  };

  // function LikeButton({ postId, slug, combinedIdentifier }) {

  Date.prototype.toShortFormat = function() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"];
    const day = this.getDate();
    const monthIndex = this.getMonth();
    const monthName = monthNames[monthIndex];
    const year = this.getFullYear();
    return `${day} ${monthName} ${year}`;  
  }

  return (
    <div>
      <div className='sidebar'>
        <section className="Blogger hidden-xs">
          <a href="/">
            <img loading="lazy" className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" /></a>
          <div className="ceo-text">
            <div className="BloggerName">ARUN NATHANI</div>
            <div className="BloggerPost">
              <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
            </div>
          </div>
        </section>
        <Category />
      </div>
      <div className="post-container main-container">
        <header className="header fixed">
          <nav className="nav navbar navbar-default">
            <div className="navbar-header">
            <a href="/the-science-of-success/" className={`navbar-brand ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}> <span>THE SCIENCE OF <b>SUCCESS</b></span>
                <span className="txtcolor">Arun Nathani</span>
              </a>
            </div>
            {isMobile && (
              
              <button className="toggle-btn" onClick={toggleMenu} type="button" data-target="#navbarCollapse" data-toggle="collapse" class={` navbar-toggle ${isOpen ? 'active' : ''}`}>
              <span className="sr-only">Toggle navigation</span> 
              <span class="icon-bar"></span>
               <span class="icon-bar"></span> 
               <span class="icon-bar"></span>
                              
          </button>
            )}
            {!isMobile && (
              <ul className="nav-list desktop-nav" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <li className={`nav-item ${currentPath === '/' ? 'active' : ''}`}>
                  <Link to="/">Home</Link>
                </li>
                <li className={`nav-item ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}>
                  <Link to="/the-science-of-success/">about</Link>
                </li>
              </ul>
            )}
            {isOpen && isMobile && (
              <ul className="nav-list mobile-nav ${isOpen ? 'open' : ''}`}">
                <li className="nav-item">
                  <Link onClick={handleisOpen}  to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/the-science-of-success/">about</Link>
                </li>
<li className="nav-item category">
<Link onClick={handleToggleCategory}>Categories
                  { !isOpen === toggle ? <i class="fa fa-plus-circle" aria-hidden="true"></i> : <i class="fa fa-minus-circle" aria-hidden="true"></i> }
                  </Link>                </li>
                {toggle && <CategoryMob />}
              </ul>
            )}
          </nav>
          <div id="navbarCollapse" class="navbar-collapse pull-right collapse in" >
            <div class="menu-arun-blog-menu-container"><ul class="nav navbar-nav"><li id="menu-item-1495" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1495"><a href="/">HOME</a></li>
              <li id="menu-item-1496" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1496"><a href="/the-science-of-success/">ABOUT</a></li>
            </ul>
            </div>   
                      {/* <section class="col-sm-12 accordionMenu category-nav hidden-sm hidden-md hidden-lg">
              <a href="#">CATEGORIES <span class="fa fa-plus"></span></a>
            </section> */}
            </div>
            {showSearchTopbar ? (<div className="topbar ">
            <div className="breadcrumb" id="breadcrumb">
              <ul id="breadcrumbs" className="breadcrumbs">
                <li className="item-home"><a className="bread-link bread-home" href="/" title="Home">Home</a></li>
                <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
                <li className="item-home search-result-for">Search results for: {searchname}</li>
              </ul>
            </div>
          </div> ) : (<div class="topbar filter">
            <div className='topbar-mobile'>      
            <ul className="col-xs-12 col-sm-6 pull-left category">
           
            <li className="col-xs-offset-1">
              <a className={filter === 'popular' ? 'active' : ''} href="/?filter=popular">
                POPULAR
              </a>
            </li>

            <li className="col-xs-offset-1">
              <a className={filter === 'latest' ? 'active' : ''} href="?filter=latest">
                LATEST
              </a>
            </li>
           
            <li className="col-xs-offset-1">
              <a className={filter === 'duration' ? 'active' : ''} href={`/?filter=duration&order=${newOrder}`}>
                LENGTH
                <span>
                  <img loading="lazy" src={`${base_url}/wp-content/themes/Arun_Blog/images/arrow.png`} className={arrowClass} alt="arrow" />
                </span>
              </a>
            </li>
            <li  onClick={handleSearchMobile} className="col-xs-offset-1 hidden-md hidden-lg">
              <a href="#" id="searchToggle" className="glyphicon glyphicon-search"><i class="fa fa-search" aria-hidden="true"></i></a>
            </li>
          </ul>
            </div>                        
          <ul class="col-xs-5 pull-right social-icons hidden-xs">
            <li><a href="https://www.facebook.com/cybage/" target='blank'><span class="st_facebook_large fa fa-brands fa-facebook-f" st_title="Arun Nathani, CEO, Cybage Software Pvt. Ltd."  st_processed="yes"><span class="stButton"><span class="stLarge"></span></span></span></a></li>
            <li><a href="http://twitter.com/cybagesoftware" target='blank'><span class="st_twitter_large fa fa-brands fa-x-twitter" st_title="Arun Nathani, CEO, Cybage Software Pvt. Ltd."  st_processed="yes"><span class="stButton"><span class="stLarge"><a></a></span></span></span></a></li>
            <li><a href="https://www.linkedin.com/company/cybage/" target='blank'><span class="st_linkedin_large fa fa-brands fa-linkedin-in" st_title="Arun Nathani, CEO, Cybage Software Pvt. Ltd."  st_processed="yes"><span class="stButton"><span class="stLarge"><a></a></span></span></span></a></li>
            </ul>
        </div>)}
        </header>
        <section style={{display: searchtoggle ? 'block' : 'none',marginTop: searchtoggle ? '90px' : '0px'}} className="col-sm-12 search-block hidden-xs">
        <form role="search" method="get" action='/' id="searchform">
          <div className="input-group search-box">
            <input className="blog-search form-control" type="text" id="search" name="s" placeholder="Search Blogs" />
            <div className="input-group-btn">
              <button type="submit" className="btn search-icon pull-right"></button>
            </div>
          </div>
        </form>
      </section>
        { isMobile && (
              <section className="mobile-Blogger hidden-xs" style={{marginTop: searchtoggle ? '0px' : '90px'}}>
                <div className="arun-mobile-imgblk">
              <a href="/the-science-of-success/">
                <img  loading="lazy" className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" /></a>
                </div>
              <div className="ceo-text">
                <div className="mobile-BloggerName BloggerName">ARUN NATHANI</div>
                <div className="mobile-BloggerPost BloggerPost">
                  <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
                </div>
              </div>
            </section>
            )}
        <div className="pager">
            {totalPages > 1 && (
              <>
                {currentPage > 1 && (
                  <button 
                    className="prev page-numbers" 
                    onClick={goToPreviousPage}
                  >
                    ← Previous
                  </button>
                )}
                <button
                  key={1}
                  className={`page-numbers ${currentPage === 1 ? 'current' : ''}`}
                  onClick={() => goToPage(1)}
                >
                  1
                </button>
                {currentPage > 3 && <span className="dots">...</span>}
                {pageRange().map((page) => (
                  page !== 1 && page !== totalPages && (
                    <button
                      key={page}
                      className={`page-numbers ${currentPage === page ? 'current' : ''}`}
                      onClick={() => goToPage(page)}
                    >
                      {page}
                    </button>
                  )
                ))}
                {currentPage < totalPages - 2 && <span className="dots">...</span>}
                <button
                  key={totalPages}
                  className={`page-numbers ${currentPage === totalPages ? 'current' : ''}`}
                  onClick={() => goToPage(totalPages)}
                >
                  {totalPages}
                </button>
                {currentPage < totalPages && (
                  <button 
                    className="next page-numbers" 
                    onClick={goToNextPage}
                  >
                    Next →
                  </button>
                )}
              </>
            )}
          </div>


        <div id="masonry" className="isotope">
        <div className="row">
        {dataLoaded  && showSearchTopbar && searchfilterdBlogs.length === 0 ? (
            <div class="empty-records">This one does not work. Take a look at the other blogs.</div>
          ) : (
             searchfilterdBlogs.map((post) => (
             <div key={post.id} className="post col-md-3 isotope-item">
                <div className={`post_content ${post.featured_image ? 'post-with-Img' : 'post-no-Img'}`}>
                  {post.featured_image && (
                    <div className="post-image-container">		
                      <a href={post.slug} rel="bookmark">
                      <img
                           title={decodeHTML(post.title.rendered)}
                           alt={decodeHTML(post.title.rendered)}
                           className="post-image responsive-img"
                           src={post.featured_image}
                        />
                      </a>
                    </div>
                  )}
                  <article className="article">
                    <h3 className="title">
                      <a href={post.slug} rel="bookmark" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                    </h3>
                    <div className="line">
                      <div className="entry-info col-xs-12">
                        <div className="entry-date pull-left">
                          <label>Date: </label>
                          <span>{new Date(post.date).toShortFormat()}</span>
                        </div>
                        <div className="entry-date pull-left">
                          <label>Length:</label>
                          {post.length_count ? `${post.length_count} min${post.length_count > 1 ? 's.' : '.'}` : '0 min'}
                        </div>
                        <div className="entry-standard entry-style col-xs-6">
                          <label>category:</label>
                          <span>
                            {categories
                              .filter(category => !['All','Style'].includes(category.name))
                              .map(category => (
                                post.categories.includes(category.id) ? (
                                  <a key={category.id} href={`/category/${category.name.toLowerCase()}`}>
                                    {category.name}
                                  </a>
                                ) : null
                              ))
                            }
                          </span>
                        </div>
                      <div className="entry-date pull-left col-xs-6">
                        <label>Sub-category:</label>
                        <span>
                          {categories.flatMap(category =>
                            category.subcategories
                              .filter(subcategory => !['All','Style','Adapted', 'Original', 'Video'].includes(subcategory.name))
                              .map(subcategory =>
                                post.categories.includes(subcategory.id) ? (
                                  <a key={subcategory.id} href={`/category/${category.name.toLowerCase()}/${subcategory.name.toLowerCase()}`}>
                                    {subcategory.name}
                                  </a>
                                ) : null
                              )
                          )}
                        </span>
                      </div>

                      </div>
                      {
                  post.story_parts != null ? (
                    [4220, 4216, 4218, 4223].includes(post.id) ? (
                      <div className='version'>
                        {Array.isArray(post.story_parts) ? (
                          post.story_parts.map((part, index) => (
                            <div key={index}>
                              <a href={part.story_link}> {`Version-${part.parts_number}`}</a>
                            </div>
                          ))
                        ) : (
                          typeof post.story_parts === 'object' ? (
                            <div>
                              {Object.keys(post.story_parts).map((key, index) => (
                                <div key={index}>
                                  <a href={post.story_parts[key].story_link}>{`Version-${post.story_parts[key].parts_number}`}</a>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Invalid story parts format.</div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className='parts'>
                        <label>Parts:</label>
                        {Array.isArray(post.story_parts) ? (
                          post.story_parts.map((part, index) => (
                            <div key={index}>
                              <a href={part.story_link}>{part.parts_number}</a>
                            </div>
                          ))
                        ) : (
                          typeof post.story_parts === 'object' ? (
                            <div>
                              {Object.keys(post.story_parts).map((key, index) => (
                                <div key={index}>
                                  <a href={post.story_parts[key].story_link}>{post.story_parts[key].parts_number}</a>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Invalid story parts format.</div>
                          )
                        )}
                      </div>
                    )
                  ) : null
                }
                    </div>
                    <p className="post-description" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                    
                  </article>
                                 
                </div>
                <div className="menu-social-links-menu-container social-icons">
                <div className="action-links">
                      <p className="read-more">
                        <button onClick={() => handleReadMore(post.slug)}>Continue Reading</button>
                      </p>
                    </div>
                    <ul className="nav-section-new">
                      <li className="nav-item menu-li-soc">
                        <a href="https://www.facebook.com/cybage/" target="_blank">
                          <span className="fa fa-facebook"></span>
                        </a>
                      </li>
                      <li className="nav-item menu-li-soc">
                        <a href="http://twitter.com/cybagesoftware" target="_blank">
                          <span className="fa fa-brands fa-x-twitter"></span>
                        </a>
                      </li>
                      <li className="nav-item menu-li-soc">
                        <a href="https://www.linkedin.com/company/cybage/"  target="_blank">
                          <span className="fa fa-linkedin"></span>
                        </a>
                      </li>
                      {/* Like button */}
                      <LikeButton postId={post.id} slug={post.slug} combinedIdentifier={combinedIdentifier} postStatus={postStatus} likes_count= {post.likes_count} />
                      {/* Like count */}
                      {/* <div onClick={scrollToSection} className="custom-like" slug={post.slug}>
                        <a href="javascript: void(0)" className="like-button" data-postid={post.id} data-slug={post.slug}>
                          <i className="fa fa-thumbs-up"></i>
                        </a>
                    
                        
                        {postStatus[post.id] && (
                          <span className="like-count" no_of_likes={postStatus[post.id].no_of_likes} id={`like_count_${post.id}`}>
                            {postStatus[post.id].likes_count}
                          </span>                        )}
                      </div> */}
                      <span className="pull-right comment-cnt">
                        <a href={`/${post.slug}#comments`} >
                          <span className="fa fa-comment" ></span> 
                          {post.comments_count ? post.comments_count : '0'}
                        </a>
                      </span>
                    </ul>
                  </div> 
              </div>
            ))
          )}
          </div>

        </div>
        <div className="pager bottom-pager">
            {totalPages > 1 && (
              <>
                {currentPage > 1 && (
                  <button 
                    className="prev page-numbers" 
                    onClick={goToPreviousPage}
                  >
                    ← Previous
                  </button>
                )}
                <button
                  key={1}
                  className={`page-numbers ${currentPage === 1 ? 'current' : ''}`}
                  onClick={() => goToPage(1)}
                >
                  1
                </button>
                {currentPage > 3 && <span className="dots">...</span>}
                {pageRange().map((page) => (
                  page !== 1 && page !== totalPages && (
                    <button
                      key={page}
                      className={`page-numbers ${currentPage === page ? 'current' : ''}`}
                      onClick={() => goToPage(page)}
                    >
                      {page}
                    </button>
                  )
                ))}
                {currentPage < totalPages - 2 && <span className="dots">...</span>}
                <button
                  key={totalPages}
                  className={`page-numbers ${currentPage === totalPages ? 'current' : ''}`}
                  onClick={() => goToPage(totalPages)}
                >
                  {totalPages}
                </button>
                {currentPage < totalPages && (
                  <button 
                    className="next page-numbers" 
                    onClick={goToNextPage}
                  >
                    Next →
                  </button>
                )}
              </>
            )}
          </div>

        <Footer />
      </div>
    </div>
  );
}

export default Blog;
